import { Box } from "@mui/material";
import React from "react";
import { Logo } from "@qualifyze/mui-theme";
import LanguageToggle from "../LanguageToggle";
import { websiteUrl } from "../config";

const Header = () => {
  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        mb: 10,
      }}
    >
      <Box sx={{ flex: "0.5", maxWidth: "10rem" }}>
        <a href={websiteUrl}>
          <Logo />
        </a>
      </Box>

      <Box>
        <LanguageToggle />
      </Box>
    </Box>
  );
};

export default Header;
