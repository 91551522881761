import { FC, useEffect, useMemo } from "react";
import { useCookies } from "react-cookie";
import { useAuth } from "@qualifyze/auth";

declare global {
  interface Window {
    dataLayer: Array<{
      event: string;
      eventData: { [key: string]: string };
      _clear: true;
      authenticated?: boolean;
      internal?: boolean;
      userId?: string;
    }>;
  }
}

// Initialize dataLayer if it's not present in the window object. Once the
// GTM script loads it will use it and the data it has been populated with.
const gtmDataLayer = window.dataLayer ?? (window.dataLayer = []);

type CustomGTMEventPayload = {
  action: string;
  label?: string;
  category?: string;
} & Record<string, string>;

export const emitCustomGtmEvent = (payload: CustomGTMEventPayload) =>
  gtmDataLayer.push({
    event: "platform-custom-event",
    eventData: payload,
    // Don't merge with `eventData` from the previous event - attribute order matters
    _clear: true,
  });

/**
 * @deprecated Use `emitCustomGtmEvent` instead.
 */
export const useTrack = () =>
  useMemo(
    () => ({
      /**
       * @deprecated Use `emitCustomGtmEvent` instead.
       */
      tracker: {
        /**
         * @deprecated Use `emitCustomGtmEvent` instead.
         */
        track({
          event,
          properties,
        }: {
          event: { object: string; action: string };
          properties?: { label: string; from?: string };
        }) {
          gtmDataLayer.push({
            event: "platform-legacy-event",
            eventData: {
              category: event.object,
              action: `${event.object} ${event.action}`,
              ...(properties?.label ? { label: properties.label } : {}),
            },
            // Don't merge with `eventData` from the previous event - attribute order matters
            _clear: true,
          });
        },
      },
    }),
    []
  );

export const GtmUserIdentity: FC = ({ children }) => {
  const auth = useAuth();
  const [cookies, setCookie, removeCookie] = useCookies(["internalUser"]);

  // Simple variables are to ensure no extra events are sent
  const authenticated = auth.authenticated;
  const userId = auth.authenticated ? auth.profile.userId : "anonymous";
  // When authenticaed, check profile if the user is internal. Otherwise, check the cookie.
  const internal = auth.authenticated
    ? auth.profile.internal === true
    : cookies.internalUser === "true";

  useEffect(() => {
    gtmDataLayer.push({
      event: "user-authentication-update",
      eventData: {},
      // Don't merge with `eventData` from the previous event - attribute order matters
      _clear: true,
      authenticated,
      internal,
      userId,
    });
  }, [authenticated, internal, userId]);

  useEffect(() => {
    // Set the cookie when an internal user is logged in
    if (internal && !cookies.internalUser) {
      setCookie("internalUser", internal, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: "/",
        maxAge: 31622400,
      });
    }

    // Remove the cookie when no internal user is logged in
    if (!internal && cookies.internalUser) {
      removeCookie("internalUser", {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        path: "/",
      });
    }
  }, [
    auth.authenticated,
    cookies.internalUser,
    internal,
    setCookie,
    removeCookie,
  ]);

  return <>{children}</>;
};
