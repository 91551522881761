import { LDProvider, useLDClient } from "launchdarkly-react-client-sdk";
import { useEffect } from "react";
import { useAuth } from "@qualifyze/auth";

import { launchDarklyClientSideId } from "../config";

const anonymous = { anonymous: true, key: "anonymous" };

export function LaunchDarklyProvider({ children }) {
  return (
    <LDProvider
      clientSideID={launchDarklyClientSideId}
      options={{
        // Avoid NetworkError due to Synchronous XHR in page dismissal
        // https://github.com/launchdarkly/js-client-sdk/issues/147
        disableSyncEventPost: true,
      }}
      reactOptions={{
        // Disable using Proxy which is not working in IE 11
        sendEventsOnFlagRead: false,
      }}
      user={anonymous}
    >
      <LaunchDarklyProviderInner>{children}</LaunchDarklyProviderInner>
    </LDProvider>
  );
}

function LaunchDarklyProviderInner({ children }) {
  const ld = useLDClient();
  const auth = useAuth();

  useEffect(() => {
    if (ld && !auth.loading) {
      void ld.identify(
        auth.authenticated
          ? {
              key: auth.profile.userId,
              email: auth.profile.admin ? auth.profile.email : undefined,
              custom: { internal: Boolean(auth.profile.internal) },
              anonymous: false,
            }
          : anonymous
      );
    }
  }, [ld, auth]);

  return <>{children}</>;
}
