import * as Sentry from "@sentry/react";
import { Component } from "react";
import GlobalError from "./GlobalError";

export default class ErrorBoundary extends Component {
  state = {
    error: undefined,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    if (this.state.error) {
      return <GlobalError error={this.state.error} />;
    }

    return this.props.children;
  }
}
