import { Button } from "@mui/material";
import { Language } from "@mui/icons-material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { languages } from "./i18n";

export default function LanguageToggle() {
  const { i18n } = useTranslation();
  const otherLanguage = languages.find((l) => l.id !== i18n.language);
  const toggleLanguage = useCallback(
    () => i18n.changeLanguage(otherLanguage.id),
    [i18n, otherLanguage]
  );

  return (
    <Button variant="text" onClick={toggleLanguage} startIcon={<Language />}>
      {otherLanguage.label}
    </Button>
  );
}
