import "@qualifyze/mui-theme/fonts/everett/index.css";
import "@qualifyze/mui-theme/fonts/tobias/index.css";

import { useEffect } from "react";
import { CssBaseline, CircularProgress } from "@mui/material";
import { ThemeProvider } from "@qualifyze/mui-theme";
import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "@qualifyze/auth";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";

import ErrorBoundary from "./components/ErrorBoundary";
import InternetExplorerModal from "./components/InternetExplorerModal";
import Layout from "./components/Layout";
import { Main } from "./components/Main";
import { FromProvider } from "./from";
import "./i18n";
import { GtmUserIdentity } from "./utils/gtm";
import { LaunchDarklyProvider } from "./utils/launchdarkly";
import { appUrl, authClientId, authDomain } from "./config";
import { titleSeparator } from "./utils/title";

function Redirection({ isHome }) {
  const pathName = isHome ? "/login" : window.location.pathname;

  useEffect(() => {
    window.location.replace(`${appUrl}${pathName}${window.location.search}`);
  }, [pathName]);

  return <CircularProgress />;
}

function App() {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <HelmetProvider>
      <Helmet
        htmlAttributes={{ lang: language }}
        titleTemplate={`%s ${titleSeparator} Qualifyze`}
      />
      <ThemeProvider>
        <CssBaseline />
        <InternetExplorerModal />
        <Layout>
          <FromProvider>
            <AuthProvider clientId={authClientId} domain={authDomain}>
              <LaunchDarklyProvider>
                <ErrorBoundary>
                  <GtmUserIdentity>
                    <Main>
                      <Routes>
                        <Route path="/" element={<Redirection isHome />} />
                        <Route path="*" element={<Redirection />} />
                      </Routes>
                    </Main>
                  </GtmUserIdentity>
                </ErrorBoundary>
              </LaunchDarklyProvider>
            </AuthProvider>
          </FromProvider>
        </Layout>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
