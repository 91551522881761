import {
  Box,
  Container,
  Hidden,
  Stack,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "@qualifyze/auth";

import { websiteUrl } from "../config";
import { useSentry } from "../sentry";
import { TextLink } from "../TextLink";
import Header from "./Header";

import landingImg from "./landing-image.jpg";

export const Main = ({ children }) => {
  const auth = useAuth();
  useSentry();

  return (
    <Box
      as="main"
      sx={{
        minHeight: "100%",
        py: [3, 5, 6],
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2} direction="row">
          <Grid item xs={12}>
            <Header />
          </Grid>

          {auth.loading ? (
            <AuthResolvingContent />
          ) : (
            <AuthResolvedContent>{children}</AuthResolvedContent>
          )}
        </Grid>
      </Container>
    </Box>
  );
};

function AuthResolvingContent() {
  return (
    <Grid item xs={12}>
      <Box display="flex" justifyContent="center" width="100%">
        <CircularProgress />
      </Box>
    </Grid>
  );
}

function AuthResolvedContent({ children }) {
  const { t } = useTranslation();

  return (
    <>
      <Grid item xs={1} />
      <Hidden lgDown>
        <Grid item xs={10} lg={4}>
          <Box
            sx={{ objectFit: "cover", width: "100%" }}
            component="img"
            src={landingImg}
          />
        </Grid>
      </Hidden>
      <Hidden lgDown>
        <Grid item lg={1} />
      </Hidden>
      <Grid item xs={10} lg={5}>
        {children}
      </Grid>
      <Grid item xs={1} />
      <Grid item xs={1} />
      <Grid item xs={10} lg={4}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: 12,
          }}
        >
          <Typography component="div" variant="body2">
            <Stack direction="row" spacing={1} divider={<span>-</span>}>
              <TextLink to={`${websiteUrl}/imprint`}>{t("IMPRINT")}</TextLink>

              <TextLink to={`${websiteUrl}/terms-conditions`}>
                {t("TERMS_CONDITIONS")}
              </TextLink>

              <TextLink to={`${websiteUrl}/data-privacy`}>
                {t("DATA_PRIVACY")}
              </TextLink>
            </Stack>
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
