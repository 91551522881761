import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TextLink } from "../TextLink";

function isInternetExplorer() {
  return /*@cc_on!@*/ false || !!document.documentMode;
}

export default function InternetExplorerModal() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(isInternetExplorer() ? true : false);

  const modalComponents = useMemo(
    () => ({
      instructions: (
        <TextLink href={t("INTERNET_EXPLORER_WARNING.LINK_ADDRESS")} />
      ),
    }),
    [t]
  );

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("INTERNET_EXPLORER_WARNING.HEADING")}</DialogTitle>
      <DialogContent>
        <Typography>
          <Trans
            i18nKey="INTERNET_EXPLORER_WARNING.TEXT"
            components={modalComponents}
          />
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpen(false)}
        >
          {t("INTERNET_EXPLORER_WARNING.BUTTON")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
