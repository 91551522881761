import queryString from "query-string";
import { createContext, useContext, useEffect, useMemo } from "react";

const key = "qualifyze:auth:from";

const Context = createContext();

export function FromProvider({ children }) {
  const search = queryString.parse(window.location.search);
  const from = useMemo(
    () => search.from ?? window.sessionStorage.getItem(key),
    [search.from]
  );

  useEffect(() => {
    saveFrom(search.from);
  }, [search.from]);

  const context = useMemo(() => ({ from }), [from]);
  return <Context.Provider value={context}>{children}</Context.Provider>;
}

export function useFrom() {
  return useContext(Context).from;
}

function saveFrom(from) {
  if (from) {
    window.sessionStorage.setItem(key, from);
  } else {
    window.sessionStorage.removeItem(key);
  }
}
