import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
// for some reason on importing the DS we get an error about global this
// thing is that the design system does not use globalThis so I am not sure
// where this error is coming from tbh there is a ref to it in the package
// lock of the ds but only story book has a dev dep on airbnb js shims and story
// book is a dev dep so it is really really strange
import "core-js/features/global-this";
import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./sentry";

ReactDOM.render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
  document.getElementById("root")
);
