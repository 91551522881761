import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ErrorAlert from "./ErrorAlert";
import { Main } from "./Main";

export default function GlobalError({ error }) {
  const { t } = useTranslation();

  return (
    <Main>
      <Typography variant="h1" mb={4}>
        {t("ERROR.HEADING")}
      </Typography>
      <ErrorAlert error={error} />
    </Main>
  );
}
