import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import { useEffect } from "react";
import { useAuth } from "@qualifyze/auth";

import { sentry, stage } from "../config";

const { dsn, tracesSampleRate } = sentry;

if (dsn) {
  Sentry.init({
    dsn,
    environment: stage,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ["error"],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: parseFloat(tracesSampleRate),
    enabled: stage === "production",
  });
}

export function useSentry() {
  const { profile } = useAuth();

  useEffect(() => {
    if (profile) {
      Sentry.setUser({
        id: profile.userId,
        email: profile.admin ? profile.email : undefined,
      });
    }
  }, [profile]);
}
