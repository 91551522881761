const {
  REACT_APP_STAGE,
  REACT_APP_APP_URL,
  REACT_APP_AUTH_CLIENT_ID,
  REACT_APP_AUTH_DOMAIN,
  REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID,
  REACT_APP_WEBSITE_URL,
  REACT_APP_CONSENT_FORM_ID,
  REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  REACT_APP_SENTRY_DSN,
} = process.env;

export const sentry = {
  dsn: REACT_APP_SENTRY_DSN,
  tracesSampleRate: REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
};

export const stage = REACT_APP_STAGE;

export const authClientId = REACT_APP_AUTH_CLIENT_ID;
export const authDomain = REACT_APP_AUTH_DOMAIN;

export const launchDarklyClientSideId = REACT_APP_LAUNCH_DARKLY_CLIENT_SIDE_ID;

export const appUrl = REACT_APP_APP_URL;
export const websiteUrl = REACT_APP_WEBSITE_URL;

export const consentFormId = REACT_APP_CONSENT_FORM_ID;
