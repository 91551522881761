import { GlobalStyles } from "@mui/material";

export default function Layout({ children }) {
  return (
    <>
      <GlobalStyles styles={{ "#root": { minHeight: "100%" } }} />
      {children}
    </>
  );
}
