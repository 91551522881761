/* eslint-disable react/prop-types */
import { Link as RouterLink } from "react-router-dom";
import { Link as BaseLink } from "@mui/material";
import { useTrack } from "./utils/gtm";

type Props = {
  from?: string;
  to: string;
  target?: string;
  children?: React.ReactNode;
} & Record<string, unknown>;

const Outgoing = ({ to, ...props }: Props & { onClick: () => void }) => {
  return <BaseLink href={to} rel="noopener" {...props} />;
};

const Internal = (props: Props & { onClick: () => void }) => {
  return <BaseLink component={RouterLink} {...props} />;
};

const TextLink = ({ from = "unknown source", ...props }: Props) => {
  const { tracker } = useTrack();

  // If a link starts with a slash, we assume it's an internal link
  const { to } = props;
  const internal = /^\/(?!\/)/.test(to);

  const onClick = () => {
    tracker.track({
      event: {
        object: "TextLink",
        action: "selected",
      },
      properties: {
        label: to,
        from,
      },
    });
  };

  return internal ? (
    <Internal onClick={onClick} {...props} />
  ) : (
    <Outgoing onClick={onClick} {...props} />
  );
};

export { TextLink };
